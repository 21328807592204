@import url("https://rsms.me/inter/inter.css");
.ai-artist h2,
.ai-artist button,
.ai-artist div {
  font-family: "Inter", sans-serif !important;
}
@supports (font-variation-settings: normal) {
  .ai-artist h2,
  .ai-artist button,
  .ai-artist div {
    font-family: "Inter var", sans-serif !important;
  }
}

.ai-artist, .ai-artist * {
  box-sizing: border-box;
}

.ai-artist h2 {
  margin: 1rem 0;
}

.ai-artist .button-section {
  display: flex;
  justify-content: space-between;
  color: #333;
}

.ai-artist.controls {
  padding: 0.5rem;
  color: #333;
  width: 100%;
  min-height: 100vh;
}

.ai-artist button {
  background-color: #222;
  color: white;
  border: 1px solid #111;
  padding: 0.25em 0.5em;
  border-radius: 0.25rem;
  margin: 0.25rem 0;
}

.ai-artist button[disabled] {
  background-color: #eee;
  border: 1px solid #ccc;
  color: #999;
}

.ai-artist .generate {
  background-color: rgba(39, 157, 39, 0.8);
  border: 1px solid rgb(39, 157, 39);
  color: white;
}

.ai-artist .flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.5rem;
}

.ai-artist .flex div {
  cursor: pointer;
  background-color: #f4f4f4;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}

.ai-artist .selected,
.ai-artist .options {
  margin: 1rem 0;
  border-radius: 0.5rem;
}

.ai-artist .selected {
  min-height: 100px;
}

.ai-artist .options {
  min-height: 300px;
}

.ai-artist .button-row {
  display: inline-flex;
  gap: 0.5rem;
}

.ai-artist .images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.ai-artist .images a {
  display: flex;
}

.ai-artist img {
  width: 100%;
  height: auto;
}

.ai-artist i {
  display: block;
  margin: 1rem 0;
}
